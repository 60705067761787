import { useEffect, useState } from "react";
import { jwtDecode } from "jwt-decode"; 
import "./App.css";

import Login from "./components/Login";
import Search from "./components/Search";

function App() {
  const [token, setToken] = useState("");

  useEffect(() => {
    const storedToken = localStorage.getItem("token");

    if (storedToken) {
      const decodedToken = jwtDecode(storedToken);
      const currentTime = Date.now() / 1000;

      if (decodedToken.exp < currentTime) {
        signOut();
      } else {
        setToken(storedToken);
      }
    }
  }, []);

  const signOut = () => {
    setToken("");
    localStorage.removeItem("token");
  };

  return (
    <div className="App">
      {!token ? (
        <Login updateToken={(createdToken) => setToken(createdToken)} />
      ) : (
        <Search token={token} signOut={signOut} />
      )}
    </div>
  );
}

export default App;
