import { useEffect, useState } from "react";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import { jwtDecode } from "jwt-decode"; // You may need to install this library

import { apiURL, mapsKey } from "../config";
import trackstarLogo from "../images/trackstar-blue.png";

function Search({ token, signOut }) {
  const [loading, setLoading] = useState(false);
  const [busNumber, setBusNumber] = useState("");
  const [busLocation, setBusLocation] = useState(null);
  const [exceptionMessage, setExceptionMessage] = useState(null);
  const [replacementBusName, setReplacementBusName] = useState(null);
  const [showNoResult, setShowNoResult] = useState(false);
  const [searchedBusNumbers, setSearchedBusNumbers] = useState([]);

  useEffect(() => {
    const searchedBusses = localStorage.getItem("searchedBusses") || "";
    setSearchedBusNumbers(
      searchedBusses ? searchedBusses.split(",").filter((num) => !!num) : []
    );
  }, []);

  const isTokenExpired = () => {
    try {
      const storedToken = localStorage.getItem("token");
      const decoded = jwtDecode(storedToken);
      if (decoded.exp && decoded.exp * 1000 < Date.now()) {
        return true;
      }
      return false;
    } catch (error) {
      console.error("Failed to decode token:", error);
      return true;
    }
  };

  const handleSubmit = async (e, clickedBusNumber) => {
    e.preventDefault();

    if (!clickedBusNumber && !busNumber) return;

    if (isTokenExpired()) {
      signOut();
      return;
    }

    setLoading(true);
    setShowNoResult(false);
    setBusLocation(null);
    setExceptionMessage(null);
    setReplacementBusName(null);

    const trimmedBusNumber = clickedBusNumber || busNumber.trim();

    try {
      const result = await axios.get(
        `${apiURL}/v1/getLocationByBusNumber?busNumber=${trimmedBusNumber}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setLoading(false);

      if (
        result.data &&
        result.data.location &&
        result.data.location.Location
      ) {
        const searchedBusses = localStorage.getItem("searchedBusses") || "";
        const searchedBussesArr = searchedBusses.split(",");

        if (!searchedBussesArr.includes(trimmedBusNumber)) {
          searchedBussesArr.push(trimmedBusNumber);
        }

        localStorage.setItem("searchedBusses", searchedBussesArr.join(","));

        setBusLocation(result.data.location.Location);
        setExceptionMessage(result.data.location.ExceptionMessage);
        setReplacementBusName(result.data.location.ReplacementBusName);
        setSearchedBusNumbers(searchedBussesArr.filter((num) => !!num));
      } else {
        setShowNoResult(true);
      }
    } catch (e) {
      console.error(e);
      setLoading(false);
      setShowNoResult(true);
    }
  };

  const renderLoading = () => {
    if (!loading) return null;

    return (
      <div
        style={{
          position: "fixed",
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          zIndex: 10000,
          backgroundColor: "rgba(0, 0, 0, .5)",
          textAlign: "center",
        }}
      >
        <CircularProgress
          style={{ color: "#fff", top: "50%", position: "absolute" }}
        />
      </div>
    );
  };

  return (
    <div>
      {renderLoading()}
      <Box
        sx={{
          padding: 3,
          borderBottomWidth: "1px",
          borderBottomStyle: "solid",
          borderBottomColor: "#ddd",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: 2,
          }}
        >
          <img src={trackstarLogo} height="40px" alt="Trackstar Logo" />

          <Button onClick={signOut}>Sign Out</Button>
        </Box>
        <Typography sx={{ marginBottom: 2 }}>
          Enter the bus number below
        </Typography>
        <form onSubmit={handleSubmit}>
          <Box
            sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
          >
            <TextField
              type="number"
              label="Bus Number"
              value={busNumber}
              onChange={(e) => setBusNumber(e.target.value)}
              sx={{ width: "100%" }}
            />
            <Button
              variant="contained"
              type="submit"
              sx={{
                padding: "16px",
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
                marginLeft: "-5px",
              }}
            >
              Submit
            </Button>
          </Box>
        </form>
        {/* Render previously searched buses */}
        {searchedBusNumbers.length > 0 && (
          <>
            <Typography sx={{ marginTop: 2, marginBottom: 2 }}>
              Previously Searched Buses
            </Typography>
            <Box>
              {searchedBusNumbers.map((num) => (
                <Button
                  key={num}
                  variant="outlined"
                  size="small"
                  sx={{ marginRight: 1, marginBottom: 1 }}
                  onClick={() => {
                    setBusNumber(num);
                    setTimeout(
                      () => handleSubmit({ preventDefault: () => {} }, num),
                      0
                    );
                  }}
                >
                  {num}
                </Button>
              ))}
            </Box>
          </>
        )}
      </Box>
      {showNoResult && (
        <Box sx={{ padding: 3 }}>
          <Typography sx={{ marginBottom: 2 }}>
            No results found for the provided bus number.
          </Typography>
        </Box>
      )}
      {replacementBusName && (
        <Box sx={{ padding: 3 }}>
          <Alert severity="warning">
            Bus {busNumber} has been replaced by bus {replacementBusName}. Bus{" "}
            {replacementBusName} shown below.
          </Alert>
        </Box>
      )}
      {exceptionMessage && (
        <Box sx={{ padding: 3, paddingTop: 0 }}>
          <Alert severity="info">{exceptionMessage}</Alert>
        </Box>
      )}
      {busLocation && (
        <iframe
          width="100%"
          height="600"
          frameBorder="0"
          scrolling="no"
          marginHeight="0"
          marginWidth="0"
          loading="lazy"
          allowFullScreen
          referrerPolicy="no-referrer-when-downgrade"
          src={`https://www.google.com/maps/embed/v1/place?key=${mapsKey}&q=${busLocation.Latitude},${busLocation.Longitude}`}
          title="Bus Location"
        />
      )}
    </div>
  );
}

export default Search;
